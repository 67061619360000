<template>
  <div class="">
      <b-row>
        <b-col
          cols="12"
          md="3"
          class=""
        >
          <b-card
            no-body
            class="mb-0 col_catalogue"
          >
            <span>
              Familles 
              <b-button
                  class="btn-icon btn-add lg"
                  v-b-modal.modal-famille
                >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </span>


            <b-list-group flush 
              v-for="famille in familles"
              :key="famille.id"
              :title="famille.name">
              <b-list-group-item 
                :class="{ 'cactive':  famille_actif == famille.id }"
                @click="changeFamille(famille)"
              >
                ( {{ famille.nb_categorie }} ) {{famille.name}}
                <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="flat-danger"
                    size="sm"
                    class="pull-right"
                    @click="deleteFamille(famille)"
                  >
                  supprimer
                </b-button>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>

        <b-col
          cols="12"
          md="3"
          class=""
        >
          <b-card
            no-body
            class="mb-0 col_catalogue"
          >
            <span>
              Catégories
                <b-button
                  class="btn-icon btn-add lg"
                  v-b-modal.modal-categorie
                >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </span>
            <b-list-group flush 
              v-for="cat in categories"
              :key="cat.id"
              :title="cat.name">
              <b-list-group-item v-show="famille_actif == cat.catalogue_famille_id"
                :class="{ 'cactive':  categorie_actif == cat.id }"
                @click="changeCategorie(cat)"
              >
                 ( {{ cat.nb_produit }} ) {{cat.name}}

                <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="flat-danger"
                    size="sm"
                    class="pull-right"
                    @click="deleteCategorie(cat)"
                  >
                  supprimer
                </b-button>



              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class=""
        >
          <b-card
            no-body
            class="mb-0 col_catalogue"
          >
            <span>
              Les produits
              <b-button
                  class="btn-icon btn-add lg"
                  @click = showPopProduit()
                >
                <feather-icon icon="PlusIcon" />
              </b-button>  
            </span>

            <b-row  v-for="produit in produits"
              :key="produit.id"
              class="row_ouvrage"
              v-show="famille_actif == produit.familly_id && categorie_actif == produit.category_id "
            >
              <b-col
                cols="12"
                md="6"
                class=""
              >
              {{produit.libelle}}
              </b-col>
              <b-col
                cols="12"
                md="2"
                class=""
              >
                {{ produit.prix }}€
              </b-col>
               
              <b-col
                cols="12"
                md="4"
                class=""
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  style="margin-right:5px"
                   @click="updateProduit(produit)"
                >
                  Modifier
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                   @click="deleteProduit(produit)"

                >
                  Supprimer
                </b-button>   
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

    <b-modal
        id="modal-famille"
        ref="my-modal"
        title="Ajouter une famille"
        ok-title="Ajouter"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        @ok="addFamille"
      >
        <form
          ref="form"
        >
          <b-form-group
            label="Nom de la famille"
            label-for="name-input"
            invalid-feedback="Name is required"
          >
            <b-form-input
              :v-model="familles"
              id="new-cat"
              placeholder="Nom de votre famille"
            />
          </b-form-group>
        </form>
    </b-modal>

    <b-modal
      id="modal-categorie"
      ref="my-modal-sous-cat"
      title="Ajouter une catégorie"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addCat"
    >
      <form
        ref="form"
      >
        <b-form-group
          label="Nom de la catégorie"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="new-sous-cat"
            :v-model="newSousCatName"
            required
          />
        </b-form-group>
        <b-form-group
          label="Nom de la famille associé"
          label-for="cat"
          invalid-feedback="Name is required"
        >
          <select  id="catid" class="select form-control">
            <option v-for="famille in familles" 
              :key="famille.id" 
              :value="famille.id"
              :selected="famille.id == famille_actif"
            >{{famille.name}}</option>
          </select>

        </b-form-group>          
      </form>
    </b-modal>

    <b-modal
      id="modal-ouvrage"
      ref="my-modal-ouvrage"
      title="Ajouter un produit"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addProduit"
    > 

      <div class="contenaire-form">
      <b-row>
        <b-col cols="12" md="8" class="" >
          <h4>Information sur le produit</h4>
          <b-row>
            <b-col cols="12" md="4" class="" >
              <b-form-group
                label="Famille"
              >
                <select  class="select form-control" v-model="famille_actif">
                  <option v-for="(famille) in familles" 
                    :key="famille.id" 
                    :value="famille.id"
                    :selected="famille.id == famille_actif"
                  >{{famille.name}}</option>
                </select>
              </b-form-group> 
            </b-col>
            <b-col cols="12" md="4" class="" >
              <b-form-group
                label="Catégorie"
              >
                <select  class="select form-control" v-model="categorie_actif">
                  <option v-for="(categorie) in categories"  v-if="(famille_actif ==  categorie.catalogue_famille_id)"
                    :key="categorie.id" 
                    :value="categorie.id"
                    
                    
                    
                  >{{categorie.name}}</option>
                </select>
              </b-form-group> 
            </b-col>

            <b-col cols="12" md="4" class="" >
              <b-form-group
                label="Produit de base ?"
              >
                <select  class="select form-control" v-model="produit.base_produit">
                  <option :value=1>Oui</option>
                  <option :value=0>Non</option>
                </select>
              </b-form-group> 
            </b-col>
            
          </b-row>

           

            <b-form-group
              label="Libellé du produit" 
              >
              <b-form-input
                id="name-input"
                v-model = "produit.libelle"
                required
              />
            </b-form-group>

            <b-form-group
              label="Marque du produit" 
              >
              <b-form-input
                id="name-input"
                v-model = "produit.marque"
                required
              />
            </b-form-group>

           

            <b-form-group
              label="Descriptif du produit" 
              >
              <quill-editor
                v-model = 'produit.description'
              />
            </b-form-group>
        </b-col>

           <b-col cols="12" md="4" class="" >
            <h4>Tarification</h4>
           

              <b-row>
                <b-col cols="12" md="12" class="" >
                  <b-form-group label="Prix HT du produit"  >
                    <b-form-input
                      id="input-pht"
                      required
                      
                      v-model.number = produit.prix
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group label="Appliquer une marge de temps"  >
                <b-form-input
                  id="input-mo"
                  v-model.number = produit.marge_time
                />
              </b-form-group>

              <b-form-group label="Appliquer une chute"  >
                <b-form-input
                  id="input-mo2"
                  
                  v-model = produit.chute
                />
              </b-form-group>

               
            


          </b-col>
      </b-row>

        
      </div>





   
    </b-modal>
  </div>
</template>

<script>
import { BTabs,  BCard , BRow, BCol,BButton, BTab, BListGroup, BFormTextarea, BFormTags,BDropdown,BDropdownForm,BDropdownDivider,BDropdownItem,BFormTag,BDropdownText , BListGroupItem, BModal, VBModal, BCardText, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BFormRadioGroup, } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import catalogueStoreModule from './catalogueStoreModule'

import modalOuvrage from './components/modal-ouvrage'
import modalCategorie from './components/modal-add-cat'
import modalSousCategorie from './components/modal-souscategorie'


import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'


export default {
    name: 'Categorie',
    components: {
    'modal-categories' : modalCategorie,
    'modal-souscategorie' : modalSousCategorie,
    'modal-ouvrage' : modalOuvrage,
    quillEditor,
    BCardText,
    vSelect,
    BTabs,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BFormTags,
    BDropdown,
    BDropdownForm,
    BFormInput,
    BDropdownDivider,
    BDropdownItem,
    BFormTag,
    BDropdownText,
    BTab,
    BRow,
    BCol,
    BButton,
    BModal,
    VBModal,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormRadioGroup,
  },
   directives: {
    Ripple,
  },
  computed:{
    filterCategorie () {
      
    }
  },
  methods: {
    test(){
      console.log(this.categories);
    },
    orderCategories(){
      this.categories.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    },
    orderFamilles(){
      this.familles.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    },
    orderProduits(){
      console.log(this.produits)
      this.produits.sort(function (a, b) {
        return a.libelle.localeCompare(b.libelle);
      });
    },
    changeFamille(famille){
      this.famille_actif = famille.id

      let checkCategorie = this.categories.find((cat, index) => cat.catalogue_famille_id == this.famille_actif);

      if(checkCategorie != undefined){
        this.categorie_actif = checkCategorie.id
      }
    },
    changeCategorie(cat){
      this.categorie_actif = cat.id
    },
    showPopProduit(){
      var empty = {
        id : 0 , 
        libelle : 'Un nouveau produit',
        description : 'Le desc du produit',
        unite : 'forfait',
        marque : 'INTENSO',
        marge_time : '0' ,
        chute : '0' ,
        prix : '0',
        base_produit : '0' , 
        familly_id : this.famille_actif, 
        category_id : this.categorie_actif , 
      }
      console.log(empty)
      this.produit = empty 
      this.$refs['my-modal-ouvrage'].show()
    },
    updateProduit(produit){
      this.produit = produit
      console.log(this.produit)
      this.$refs['my-modal-ouvrage'].show()
    },
    addProduit(){
      this.produit.familly_id = this.famille_actif
      this.produit.category_id = this.categorie_actif
     
        store.dispatch('catalogue-produit/addProduit' , this.produit )
          .then(response => { 
            console.log(response)
            if(response.status == 201){   
              this.produit.id = response.data.produit.id
              this.produits.push(this.produit)
              this.categories.find((cat, index) => cat.id == response.data.produit.category_id).nb_produit++;

            }
             this.orderProduits()

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: '👋'+response.data.message,
                variant : 'success'
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: error,
                variant : 'danger'
              },
            })
          })
        


      
      
   

  
    },
   
    deleteProduit(produit){
      this.$bvModal
        .msgBoxConfirm('Voulez-vous supprimer le produit ?', {
          title: 'Confirmation',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value==true){
            store.dispatch('catalogue-produit/delProduit' , produit )
            .then(response => { 
              let idx = this.produits.indexOf(produit)
              this.produits.splice(idx,1)

              this.categories.find((cat, index) => cat.id == produit.category_id).nb_produit--;




              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: '👋 le produit a été supprimé.',
                  variant : 'success'
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: error,
                  variant : 'danger'
                },
              })
            })

          }
         
        })
    },
    addCat(){
      const newSousCat = document.querySelector("#new-sous-cat").value; 
      const categoriesid = document.querySelector("#catid").value;
      var cat = {
        name: newSousCat,
        catalogue_famille_id: categoriesid,
        catalogue_id: 3,
        nb_produit : 0 
      }

      this.familles.find((famille, index) => famille.id == categoriesid).nb_categorie++;

      store.dispatch('catalogue-produit/addCategorie' , cat )
      .then(response => { 
        cat.id = response.data.cat.id
        this.categories.push(cat)
        this.orderCategories()

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: '👋 la categorie de produit a été crée.',
            variant : 'success'
          },
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: error,
            variant : 'danger'
          },
        })
      })
      
    },
    deleteCategorie(cat){
      this.$bvModal
        .msgBoxConfirm('Voulez-vous supprier la catégorie de produit ?', {
          title: 'Confirmation',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value==true){
            store.dispatch('catalogue-produit/delCategorie' , cat )
            .then(response => { 
              let idx = this.categories.indexOf(cat)
              this.categories.splice(idx,1)
              this.familles.find((famille, index) => famille.id == cat.catalogue_famille_id).nb_categorie--;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: '👋 la categorie a été supprimé.',
                  variant : 'success'
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: error,
                  variant : 'danger'
                },
              })
            })

          }
         
        })
    },
    addFamille(){
      const newFamille = document.querySelector("#new-cat").value;
      var famille = {
        name: newFamille,
        catalogue_id:3,
        nb_categorie : 0, 
      }
      store.dispatch('catalogue-produit/addFamille' , famille )
      .then(response => { 
        famille.id = response.data.famille.id
        this.familles.push(famille);
        this.orderFamilles()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: '👋 la famille de produit a été crée.',
            variant : 'success'
          },
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: error,
            variant : 'danger'
          },
        })
      })
    },
    deleteFamille(famille){
      this.$bvModal
        .msgBoxConfirm('Voulez-vous supprier la famille de produit ?', {
          title: 'Confirmation',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value==true){
            store.dispatch('catalogue-produit/delFamille' , famille )
            .then(response => { 
              let idx = this.familles.indexOf(famille)
              this.familles.splice(idx,1)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: '👋 la famille a été supprimée.',
                  variant : 'success'
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: error,
                  variant : 'danger'
                },
              })
            })

          }
         
        })
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
     handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    changePrice(event) {
      this.price_actif = event.target.value*1
      this.ouvrage.prix_heure = this.price_actif*1 * this.ouvrage.heure*1
      this.ouvrage.total = this.ouvrage.prix_heure*1 + this.ouvrage.fourniture*1
    },
    onOptionClick({ option, addTag }) {
      addTag(option)
      this.search = ''
    },
    
  },
  data(){ 
    var produit = {
      id : 0 , 
      libelle : 'Un nouveau produit',
      description : 'Le desc du produit',
      unite : 'forfait',
      marque : 'INTENSO',
      marge_time : '0' ,
      chute : '0' ,
      prix : '0',
      base_produit : '0' , 
      familly_id : this.famille_actif, 
      category_id : this.categorie_actif , 
    }

    var price_actif = 1 ;

    return {
        produit,
        price_actif,
        options: this.catalogue_produit,
        search: '',
        //codeAdvanceCustom,
    }
  },
  watch: {
    'famille_actif' : function(val){
       
    },
    'categories' : function(cat){
      //console.log('Categories')
     
      
    },
    
  
  },
  computed: {
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase()
    },
    availableOptions() {
      const { criteria } = this

      // Filter out already selected options
      const options = this.options.filter(opt => this.ouvrage.tags.indexOf(opt) === -1)
      if (criteria) {
        // Show only options that match criteria
        return options.filter(opt => opt.toLowerCase().indexOf(criteria) > -1)
      }

      // Show all options available
      return options
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'There are no tags matching your search criteria'
      }
      return ''
    },
  },
  setup(){
    const familles = ref(null)
    const categories = ref(null)
    const produits = ref(null)
    const prices = ref(null)
    

    var price_actif = 55 ; 
    
    let newSousCatName = "";

    let famille_actif = ref(null) ; 
    let categorie_actif = ref(null) ; 
    let ouvrage_actif = 1  ; 

    const USER_APP_STORE_MODULE_NAME = 'catalogue-produit'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, catalogueStoreModule)
    
    store.dispatch('catalogue-produit/fetchCatalogue')
      .then(response => { 
        familles.value = response.data.familles
        categories.value = response.data.categories
        produits.value = response.data.items
        prices.value = response.data.prices
        price_actif = response.data.prices[0]

        //INIT 
        famille_actif.value = familles.value[0].id
        let checkCategorie = categories.value.find((cat, index) => cat.catalogue_famille_id == famille_actif.value);

        if(checkCategorie != undefined){
          categorie_actif.value = checkCategorie.id
        }
      
        familles.value.map(function(famille, key) {
          famille.nb_categorie = 0
        });

        categories.value.map(function(cat, key) {
          cat.nb_produit = 0
        });

        /*
        categories.value.map(function(c, key) {
            familles.value.filter((famille, index) => famille.id == c.catalogue_famille_id)[0].nb_categorie++;
        })
        */

        categories.value.map(function(c, key) {
          var isExist =  familles.value.filter((famille, index) => famille.id == c.catalogue_famille_id)[0];
          if( isExist != undefined){  isExist.nb_categorie++ }
        })

        console.log(categories.value)

        produits.value.map(function(p, key) {
            if(categories.value.filter((cat, index) => cat.id == p.category_id)[0] != undefined){
              categories.value.filter((cat, index) => cat.id == p.category_id)[0].nb_produit++;
            }
           
        })
      })
      .catch(error => {
        console.log("L erreur")
        console.log(error);
    })

    const catalogue_produit = ['Apple', 'Orange', 'Banana', 'Lime', 'Peach', 'Chocolate', 'Strawberry']; 

    const unites = {
      0 : "forfait",
      1 : "m2",
      2 : "m3",
      3 : "linéaire",
      4 : "kilos",
      5 : "unité"
    }

     

  

   
    return {
      categories,
      familles,
      produits,
      prices,
      famille_actif,
      categorie_actif,
      newSousCatName, 
      price_actif,
      catalogue_produit,
      unites
    }
  }
}
</script>
<style lang="scss" scoped>
.col_catalogue{
  position: relative;
  height: 70vh;
  overflow: scroll;
  span{
    display: block;
    position: sticky;
    top: 0px;
    color: #FFF;
    background: #7367f0;
    font-weight: bold;
    z-index: 9;
    left: 0;
    right: 0;
    padding: 20px;
  }

}

.contenaire-form{
  background: #FFF;
  padding:30px;
  margin-bottom: 30px;
}
.cactive{
  background: #b9b9bd;
  color:#FFF;
}

.row_ouvrage{
  padding: 5px 10px;
  background: #FFF;
}

.btn-add{
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 60px;
  border-radius: 0px;
  border:0px;
  background:#FFF !important;
  color:#7367f0 !important;
  font-size: 20px;
}


.for{
  display: flex;
  flex-wrap: wrap;
  .name{
    width: 100%;
    padding: 5px;
  }
  .asso{
  width: 50%;
  padding: 5px;

}
}

.select{
  width: 100%;
      border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    padding: 0.438rem 2rem 0.438rem 1rem;
    height: 38px;

}
.pull-right{
  float: right;
}
.list-group .list-group-item:hover {
    background-color: #f8f8f8;
    color : #6e6b7b;
    cursor: pointer;
}


</style>
