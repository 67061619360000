import axios from '@axios'
import {parse, stringify} from 'flatted';

//var url = "http://127.0.0.1:8000/api";
var url = "https://api.pedra.fr/api";



export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCatalogues(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(url+'/catalogues', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCatalogue(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(url+`/catalogues/3`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFamille(ctx, familleData) {
      return new Promise((resolve, reject) => {
        axios
          .post(url+'/catalogue/famille', { famille: familleData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delFamille(ctx , familleData){
      console.log('axios famille supprime')
      var id = familleData.id
      console.log(id)
      return new Promise((resolve, reject) => {
        axios
          .delete(url+'/catalogue/famille/'+ id )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCategorie(ctx, catData) {
      return new Promise((resolve, reject) => {
        axios
          .post(url+'/catalogue/categorie', { cat: catData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delCategorie(ctx , catData){
      console.log('axios cat supprime')
      console.log(catData)
      var id = catData.id
      console.log(id)
      return new Promise((resolve, reject) => {
        axios
          .delete(url+'/catalogue/categorie/'+ id )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProduit(ctx, produit) {
      console.log('axios')
      //ouvrage.tags = stringify(ouvrage.tags)
      console.log(produit)
      return new Promise((resolve, reject) => {
        axios
          .post(url+'/produits', { produit: produit })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    majProduit(ctx, produit) {
      console.log('axios maj')
      console.log(produit)
      var id = produit.id
      return new Promise((resolve, reject) => {
        axios
          .put(url+'/produits/'+id, { produit: produit })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delProduit(ctx , produit){
      console.log('axios supprime')
      console.log(produit)
      var id = produit.id
      console.log(id)
      return new Promise((resolve, reject) => {
        axios
          .delete(url+'/produits/'+ id )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    }
  },
}
